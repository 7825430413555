<template>
  <div class="five-games" :class="{ 'away-team': isAwayTeam, 'is-mobile': isMobile }">
    <div class="five-games__header" :class="{ 'away-team': isAwayTeam, 'five-games__header--is-mobile': isMobile }">
      <div class="five-games__header__logo">
        <img v-if="teamData.logo" :src="teamData.logo" alt="" />
      </div>
      <div class="five-games__header__name">
        {{ teamData.name }}
      </div>
    </div>
    <div class="five-games__table">
      <div
        v-for="(row, index1) of teamData.tableRows"
        :key="index1"
        class="five-games__table__row"
        :style="{ 'grid-template-columns': `${firstColumnWidth} repeat(5, 1fr)` }"
      >
        <template v-if="row.type === 0">
          <div
            v-for="(item, index2) of row.values"
            class="five-games__table__row__cell rival-logo"
            :class="{ 'rounded-top': index1 === 0 }"
            :key="index2 + 10"
          >
            <img v-if="item" :src="item" alt="" style="width: 1.5rem; height: 1.5rem" />
          </div>
        </template>
        <template v-if="row.type === 1">
          <div
            v-for="(item, index2) of row.values"
            class="five-games__table__row__cell"
            :class="{
              'rounded-bottom': index1 === teamData.tableRows.length - 1,
              'row-name': index2 === 0,
              text: index2 > 0,
            }"
            :key="index2 + 10"
          >
            <div
              :class="{
                'five-games__table__row__cell__first': index2 === 0,
                'bottom-dashed': index2 === 0 && index1 > 0 && index1 < teamData.tableRows.length - 1,
                data: index2 !== 0,
              }"
              :style="styleByType"
            >
              {{ item }}
            </div>
          </div>
        </template>
        <template v-if="row.type === 2">
          <div
            v-for="(item, index2) of row.values"
            class="five-games__table__row__cell"
            :class="{
              'rounded-bottom': index1 === teamData.tableRows.length - 1,
              'row-name': index2 === 0,
              text: index2 > 0,
            }"
            :key="index2 + 10"
          >
            <div
              :class="{
                'five-games__table__row__cell__first': index2 === 0,
                'bottom-dashed': index2 === 0 && index1 > 0 && index1 < teamData.tableRows.length - 1,
                'game-score': index2 > 0,
                data: index2 !== 0,
              }"
              :style="styleByType"
            >
              <template v-if="index2 === 0">
                {{ item }}
              </template>
              <template v-else>
                <div class="team" :class="{ 'team-score': item[0].isTeamScore }">
                  <div>{{ item[0].score }}</div>
                </div>
                <div class="separator">-</div>
                <div class="team" :class="{ 'team-score': item[1].isTeamScore }">
                  <div>{{ item[1].score }}</div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableFiveGames',
  props: {
    isAwayTeam: {
      type: Boolean,
      default: false,
    },
    teamData: {
      type: Object,
      default: () => ({
        name: '',
        logo: '',
        tableRows: [],
      }),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    firstColumnWidth: {
      type: String,
      default: '28%',
    },
    isGoals: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleByType() {
      return this.isGoals ? { padding: '0.62rem 0' } : { 'text-align': 'center', padding: '0.28rem 0' };
    },
  },
};
</script>

<style scoped lang="scss">
$borderRadius: 10px;

.five-games {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: Roboto-Medium, sans-serif;

  &.away-team {
    align-items: flex-end;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &.away-team {
      flex-direction: row-reverse;
    }

    & > div {
      margin: 0 0.25rem;
    }

    &__logo {
      height: 3.5rem;
      width: 3.5rem;
    }

    &__name {
      font-family: Roboto-Black, sans-serif;
      padding-top: 0.2rem;
      font-size: 1.625rem;
      font-weight: 800;
      text-transform: capitalize;
    }

    &--is-mobile {
      flex-direction: row !important;
    }
  }

  &__table {
    width: 100%;
    padding: 0.75rem;
    border-radius: 12px;
    border: solid 2px #cbee6b;

    &__row {
      display: grid;

      & > div:first-child {
        margin-right: -0.6rem;
      }

      &__cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        & > div {
          &.game-score {
            padding: 0.4rem 0 0.7rem;

            @media screen and (max-width: 400px) {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
            }
          }
        }

        &__first {
          margin-right: 0.6rem;
          width: 100%;
          text-align: right;
        }

        &:nth-child(2n) {
          background-color: #f7fafc;
        }
        @media screen and (max-width: 371px) {
          padding: 0 0.3125em;
        }
        @media screen and (max-width: 330px) {
          padding: 0 0.2em;
        }
      }
    }
  }
}

.data {
  font-family: Roboto-Black, sans-serif;
}

.rival-logo {
  padding: 0.25rem 0;
}

.row-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4d4f52;
}

.text {
  font-weight: 600;
  font-size: 0.875rem;
}

.rounded-top {
  border-radius: $borderRadius $borderRadius 0 0;
}

.rounded-bottom {
  border-radius: 0 0 $borderRadius $borderRadius;
}

.bottom-dashed {
  border-bottom: 1px dashed #bcccd4;
}

.game-score {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .team {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.team-score > div {
      &:last-child {
        background-color: #4496f4;
        height: 1rem;
        width: 1rem;
        border-radius: 4px;
        line-height: 1.3;
        color: white;
      }
    }

    & > div {
      &:last-child {
        height: 1rem;
        width: fit-content;
        line-height: 1.3;
        font-size: 0.8rem;
        color: #132839;
      }
    }
  }

  & > .separator {
    margin: 0 0.3rem;

    @media screen and (max-width: 434px) {
      margin: 0.1em;
    }
  }
}

.is-mobile {
  align-items: center !important;
}
</style>
